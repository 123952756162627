import React, { useEffect, useState } from 'react';
import { MdArrowForwardIos, MdArrowBackIosNew } from 'react-icons/md';

const Pagination = ({
  children,
  itemsPerPage = 1,
  layoutStyle = '',
  buttonStyle,
  scrollIntoViewId = '',
}) => {
  const totalItems = React.Children.count(children);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginatedChildren = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return React.Children.toArray(children).slice(startIndex, endIndex);
  };

  const scrollTo = (id) => {
    const slider = document.getElementById(id);
    slider.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollTo(scrollIntoViewId);
  }, [currentPage]);

  return (
    <>
      <div className={layoutStyle}>{renderPaginatedChildren()}</div>
      <div className="flex items-center justify-center gap-x-4">
        <button onClick={handlePrev} disabled={currentPage === 1} className={buttonStyle}>
          <MdArrowBackIosNew />
        </button>
        <div className="flex flex-wrap items-center justify-center gap-x-2">
          {Array.from({ length: totalPages }, (page, index) => (
            <button
              key={index}
              className={`${
                currentPage === index + 1 ? 'text-red' : ''
              } cursor-pointer rounded-full p-1 !font-normal leading-3 focus:outline-none`}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button onClick={handleNext} disabled={currentPage === totalPages} className={buttonStyle}>
          <MdArrowForwardIos />
        </button>
      </div>
    </>
  );
};

export default Pagination;
