import moment from 'moment';
import React from 'react';
import JobChips from './JobChips';
import MarkDownPreview from '@components/reuseable/MarkDownPreview';
import { useStore } from '@globalStore/storeProvider';
import ShareAndEarn from './ShareAndEarn';
import SiteHeader from '@components/shared/SiteHeader';
import { useRouter } from 'next/router';
import { HierarchyPrimary, Primary, SecondaryColor } from 'stories/Button.stories';

function JobDetails({ JobData = {} }) {
  const {
    jobId,
    title,
    companyName,
    location,
    postedAt,
    locationRequirement,
    minExperienceRequired,
    maxExperienceRequired,
    employmentType,
    domain,
    description,
    thumbnail,
    skills,
  } = JobData;

  const { showStripBanner } = useStore();

  const router = useRouter();

  const list = [
    locationRequirement,
    minExperienceRequired ? `${minExperienceRequired} years exp` : '',
    domain,
    employmentType,
  ];

  const PRE_FIX = 'https://grow.almabetter.com/job-board';

  const metaTitle = `${title} - ${location?.split(',')?.[0]} - ${companyName} - Almabetter.com`;

  const metaDescription = metaDescriptionBasedOnEmployementType({
    title,
    companyName,
    location,
    minExperienceRequired,
    maxExperienceRequired,
    employmentType,
  });

  const metaUrl = `https://www.almabetter.com${router.asPath}`;

  return (
    <>
      <SiteHeader title={metaTitle} desc={metaDescription} url={metaUrl} />
      <div className="my-8 md:mx-16">
        <div className="grid w-full grid-cols-1 gap-x-10 pb-10 md:grid-cols-6">
          <div className="md:col-span-4">
            <div
              className="flex flex-col gap-y-4 rounded-t-xl bg-white p-4 md:rounded-2xl md:rounded-lg md:px-4 md:py-5"
              style={{
                boxShadow: '1px 1px 6px 0 rgba(0, 0, 0, 0.2)',
              }}
            >
              <div className="flex items-start justify-between">
                <div className="flex items-start gap-x-4">
                  {thumbnail && (
                    <div className="h-16 w-16">
                      <img
                        src={thumbnail}
                        className="h-14 w-14 rounded-full border border-[#E0E0E0] object-contain"
                      />
                    </div>
                  )}

                  <div className="flex flex-col">
                    <h3 className="font-satoshi-bold text-lg text-[#3D3D3D]">{title?.trim()}</h3>
                    <p className="font-satoshi-medium text-base text-[#646464]">
                      {companyName}, {location}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex flex-wrap items-center gap-2">
                  {list.map((li) => li && <JobChips key={li} elem={li} />)}
                </div>
                <p className="block w-36 font-satoshi-medium text-[14] text-[#646464]">
                  {moment(postedAt).format('DD MMM, yyyy')}
                </p>
              </div>
              <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
                <p className="font-satoshi-medium text-sm">Skills:</p>
                <div className="flex flex-wrap items-center gap-2">
                  {skills.map(
                    (skill) =>
                      skill && (
                        <JobChips customStyle="border-blue text-blue" key={skill} elem={skill} />
                      )
                  )}
                </div>
              </div>
              <div className="p-4 font-satoshi-medium">
                <MarkDownPreview content={description} />
              </div>
            </div>
          </div>
          <div
            className={`sticky bottom-0 md:col-span-2 ${
              showStripBanner ? 'md:top-36' : 'md:top-20'
            } z-20 flex flex-col gap-y-8 self-start md:z-0`}
          >
            <div className="flex w-full flex-col items-center gap-y-4 bg-[#F6F6F6] p-3 md:bg-transparent md:p-0">
              <div className="flex w-full justify-center gap-x-6 rounded-2xl pt-2 md:flex-col md:gap-y-1 md:bg-white md:py-6 md:px-4 md:shadow-[rgba(60,64,67,0.15)_0_2px_6px_2px]">
                <Primary
                  linkCTA
                  label="Apply for this job"
                  href={`${PRE_FIX}/${jobId}`}
                  target="_blank"
                />

                <p className="hidden text-center font-satoshi-medium md:block">or</p>

                <SecondaryColor
                  linkCTA
                  label="Save for later"
                  href={`${PRE_FIX}/${jobId}`}
                  target="_blank"
                />
              </div>

              <HierarchyPrimary
                linkCTA
                href={`${PRE_FIX}/${jobId}`}
                target="_blank"
                customStyle={'text-[#245DCA] md:hidden flex flex-col'}
                label={''}
                size={'large'}
              >
                <span>Share this job with your friends to earn</span>
                <span>
                  <span>AlmaCoins in your wallet.</span>{' '}
                  <span className="underline">Share now</span>
                </span>
              </HierarchyPrimary>
            </div>
            <div className="hidden w-full md:block">
              <ShareAndEarn link={`${PRE_FIX}/${jobId}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDetails;

function metaDescriptionBasedOnEmployementType(jobData = {}) {
  const {
    title,
    location,
    companyName,
    employmentType,
    minExperienceRequired,
    maxExperienceRequired,
  } = jobData;

  let description = '';

  if (employmentType === 'Permanent') {
    description = `Apply for ${title}, ${companyName} in ${location?.split(',')?.[0]}`;

    if (minExperienceRequired && maxExperienceRequired) {
      description += ` for ${minExperienceRequired} - ${maxExperienceRequired} years of Experience on Almabetter.com`;
    } else {
      description += ` for ${employmentType} Job on Almabetter.com`;
    }

    return description;
  }

  description = `Apply for ${title}, ${companyName} in ${
    location?.split(',')?.[0]
  } for ${employmentType} Job on Almabetter.com`;

  return description;
}
