import React from 'react';

function ShareAndEarn({ link = '' }) {
  return (
    <a
      href={link}
      target="_blank"
      className="flex flex-col gap-y-2 rounded-xl bg-gradient-to-t from-[#F595C9] to-[#5B66CF] p-4"
    >
      <img src="/assets/gift-wrapper.svg" className="h-12 self-start object-contain" />
      <p className="font-satoshi-medium text-lg font-semibold text-white">
        Share this job with your friends to earn cash in your wallet on every sign up
      </p>
      <button className="flex w-full items-center justify-between rounded-lg bg-white px-2 py-1 normal-case text-blue">
        <span className="flex w-full items-center justify-center font-satoshi-bold">
          Share and Earn
        </span>
        <img src="/assets/upiIcon.svg" />
      </button>
    </a>
  );
}

export default ShareAndEarn;
