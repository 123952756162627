import React, { useState, useEffect } from 'react';

const SliderModal = ({ isOpen, onClose, children }) => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  useEffect(() => {
    setIsSliderOpen(isOpen);

    if (!isOpen) return;

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsSliderOpen(false);
    onClose();
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-[113] flex h-full items-end justify-center bg-black bg-opacity-50 ${
          isSliderOpen ? 'block' : '!hidden'
        }`}
        onClick={handleClose}
      >
        <div className="modal-content animate-slide-in-up w-full">{children}</div>
      </div>
    </>
  );
};

export default SliderModal;
