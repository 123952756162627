import { useStore } from '@globalStore/storeProvider';
import React, { useEffect, useState } from 'react';
import SliderModal from './SliderModal';
import JobsFilter from './JobsFilter';
import { IoFilter } from 'react-icons/io5';
import JobsCard from './JobsCard';
import Pagination from './Pagination';
import { useRouter } from 'next/router';
import SiteHeader from '@components/shared/SiteHeader';

function JobListing({
  jobs = [],
  filterOptions = {},
  filtersBasedOnParams = {},
  fetchJobsData,
  title = '',
}) {
  const { showStripBanner } = useStore();
  const [jobsData, setJobsData] = useState(jobs);

  const [filters, setFilters] = useState(filtersBasedOnParams);
  const [openFilterTab, setOpenFilterTab] = useState(false);

  const router = useRouter();

  const fetchJobsBasedOnFilters = async (filters) => {
    const tempFilters = { ...filters };

    if (tempFilters?.experience) {
      const value = filterOptions?.workExperiences?.find(
        (option) => option.label === tempFilters.experience
      )?.value;

      tempFilters.experience = value;
    }

    const filteredJobsData = await fetchJobsData(null, tempFilters);
    setJobsData(filteredJobsData);
  };

  useEffect(() => {
    const isQueryStringExist = Object.keys(router.query).length > 0;

    if (isQueryStringExist) {
      const preFilledFillters = router.query;

      router.query?.jobs && delete preFilledFillters.jobs;

      if (isString(preFilledFillters?.location)) {
        preFilledFillters.location = [preFilledFillters.location];
      }

      if (isString(preFilledFillters?.companies)) {
        preFilledFillters.companies = [preFilledFillters.companies];
      }

      if (isString(preFilledFillters?.skills)) {
        preFilledFillters.skills = [preFilledFillters.skills];
      }

      setFilters({
        ...filters,
        ...preFilledFillters,
      });
    } else if (!isQueryStringExist && Object.keys(filters).length > 0) {
      setFilters({});
    }

    return () => {
      setFilters({});
      setJobsData([]);
      setOpenFilterTab(false);
    };
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;

    router.push({
      pathname,
      query: filters,
    });
    fetchJobsBasedOnFilters(filters);
  }, [filters]);

  const changeHandler = (name, value) => {
    const val = value === 'Any' ? '' : value;

    if (!val || val?.length === 0) {
      delete filters[name];

      setFilters({
        ...filters,
      });

      return;
    }

    setFilters({
      ...filters,
      [name]: val,
    });
  };

  return (
    <>
      <SiteHeader
        title={`${title || 'Jobs'} - AlmaBetter`}
        desc="Find and apply for job opportunities in Data Science and Software Engineering"
        url={'https://www.almabetter.com/jobs'}
      />
      <div className="mb-10 flex flex-col items-start md:mx-16" id="jd-top">
        <div className="mx-6 flex flex-col items-start gap-y-2 pt-6 pb-10">
          <h1 className="font-satoshi-bold text-3xl">{title || 'AlmaBetter Jobs'}</h1>
          <p className="font-satoshi-medium text-lg text-[#555E6C]">
            Find and apply for job opportunities in Data Science and Software Engineering
          </p>
        </div>
        <div className="grid w-full grid-cols-1 gap-x-10 pb-10 md:grid-cols-6">
          <div className="mx-6 flex flex-col gap-y-10 md:col-span-4">
            <Pagination
              itemsPerPage={10}
              layoutStyle="flex flex-col gap-y-6"
              scrollIntoViewId="jd-top"
              buttonStyle={'border border-[#8E99AC] focus:border-red p-3 rounded-lg'}
            >
              {jobsData.map((elem) => (
                <JobsCard key={elem?.jobId} jobData={elem} />
              ))}
            </Pagination>
          </div>

          <div
            className={`sticky ${
              showStripBanner ? 'top-28' : 'top-16'
            } col-span-2 hidden self-start pb-12 md:block`}
          >
            <JobsFilter
              filterOptions={filterOptions}
              filters={filters}
              changeHandler={changeHandler}
              additionalStyle={`${showStripBanner ? 'h-[80vh]' : 'h-[85vh]'} slim-scroll`}
            />
          </div>
        </div>

        <div
          className="sticky bottom-0 z-20 grid w-full grid-cols-1 gap-x-6 border-t bg-white p-4 md:hidden"
          style={{
            boxShadow:
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
          }}
        >
          <button
            className="flex items-start justify-center gap-x-2 rounded-lg border py-2"
            onClick={() => setOpenFilterTab(true)}
          >
            <IoFilter size={24} />
            <p className="text-base !normal-case">Filters</p>
          </button>
        </div>

        <SliderModal isOpen={openFilterTab} onClose={() => setOpenFilterTab(false)}>
          <JobsFilter
            filterOptions={filterOptions}
            filters={filters}
            changeHandler={changeHandler}
            additionalStyle={'relative w-full h-fit slim-scroll'}
          />
        </SliderModal>
      </div>
    </>
  );
}

export default JobListing;

function isString(val) {
  return typeof val === 'string';
}
