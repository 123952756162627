import React, { useEffect, useState } from 'react';
import SelectDropDown from './SelectDropDown';
import SearchBox from './SearchBox';
import JobChips from './JobChips';

function JobsFilter({ filterOptions = {}, filters, changeHandler, additionalStyle = '' }) {
  const addAnyPreFix = (array = []) => {
    if (array.includes('Any')) {
      return array;
    }

    array.unshift('Any');

    return array;
  };

  return (
    <div
      className={`flex flex-col items-start gap-y-4 rounded-2xl bg-white p-4 ${additionalStyle}`}
      style={{
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <h4 className="font-satoshi-bold text-base text-[#3D3D3D]">Search and filter jobs</h4>
      <div className={`flex w-full flex-col items-start gap-y-4`}>
        <SearchBox
          label={'Search Jobs'}
          placeholder={'Search by role'}
          name="role"
          query={filters.role || ''}
          changeHandler={changeHandler}
        />
        <SelectDropDown
          customClass={'w-full'}
          label={'Location'}
          placeholder={'Select Location'}
          name="location"
          options={filterOptions?.locations}
          selectedValues={filters.location}
          changeHandler={changeHandler}
          multiple
        />
        <div className="flex flex-col items-start gap-y-1">
          <p className="font-satoshi-medium text-sm font-medium  text-gray-700">Job Location</p>
          <div className="flex items-center gap-x-4">
            {addAnyPreFix(filterOptions?.jobLocations).map((location) => (
              <JobChips
                key={location}
                customStyle={`cursor-pointer ${
                  location === filters.jobLocation || (!filters.jobLocation && location === 'Any')
                    ? 'bg-blue text-white'
                    : ''
                }`}
                elem={location}
                name="jobLocation"
                changeHandler={changeHandler}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-1">
          <p className="font-satoshi-medium text-sm font-medium  text-gray-700">Domain</p>
          <div className="flex items-center gap-x-4">
            {addAnyPreFix(filterOptions?.domains).map((domain) => (
              <JobChips
                key={domain}
                customStyle={`cursor-pointer ${
                  (!filters.domain && domain === 'Any') || domain === filters.domain
                    ? 'bg-blue text-white'
                    : ''
                }`}
                elem={domain}
                name="domain"
                changeHandler={changeHandler}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-1">
          <p className="font-satoshi-medium text-sm font-medium  text-gray-700">Employment Type</p>
          <div className="flex items-center gap-x-4">
            {addAnyPreFix(filterOptions?.employmentTypes).map((type) => (
              <JobChips
                key={type}
                customStyle={`cursor-pointer ${
                  (!filters.employmentType && type === 'Any') || type === filters.employmentType
                    ? 'bg-blue text-white'
                    : ''
                }`}
                elem={type}
                name="employmentType"
                changeHandler={changeHandler}
              />
            ))}
          </div>
        </div>
        <SelectDropDown
          customClass={'w-full'}
          label={'Companies'}
          placeholder={'Select Company'}
          name="companies"
          options={filterOptions?.companies}
          selectedValues={filters.companies}
          changeHandler={changeHandler}
          multiple
          dropdownPosition="top"
        />

        <SelectDropDown
          customClass={'w-full'}
          label={'Skills'}
          placeholder={'Select Skills'}
          name="skills"
          options={filterOptions?.skills}
          selectedValues={filters.skills}
          changeHandler={changeHandler}
          multiple
          dropdownPosition="top"
        />

        <SelectDropDown
          customClass={'w-full'}
          label={'Work Experience'}
          placeholder={'Select Work Experience'}
          name="experience"
          options={filterOptions?.workExperiences}
          selectedValues={filters.experience}
          changeHandler={changeHandler}
          dropdownPosition="top"
        />
      </div>
    </div>
  );
}

export default JobsFilter;
