import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { IoIosArrowDown } from 'react-icons/io';
import JobChips from './JobChips';

const SelectDropDown = ({
  customClass,
  label,
  placeholder,
  name,
  options = [],
  selectedValues = [],
  changeHandler,
  multiple = false,
  dropdownPosition = 'bottom',
}) => {
  const getPositionClasses = () => {
    switch (dropdownPosition) {
      case 'top':
        return 'bottom-full';
      case 'center':
        return 'top-1/2 -translate-y-1/2';
      default:
        return 'top-full';
    }
  };

  return (
    <div className={customClass}>
      <Listbox
        value={selectedValues}
        onChange={(value) => changeHandler(name, value)}
        multiple={multiple}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block font-satoshi-medium text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className={`relative mt-1`}>
              <Listbox.Button className="relative flex w-full items-center justify-between rounded-xl border border-[#D3D3D3] px-4 py-3 text-left font-satoshi-medium font-medium  normal-case text-[#2B2B2B] sm:text-sm">
                <span className="flex flex-wrap gap-x-2 gap-y-1 truncate font-satoshi-medium">
                  {Array.isArray(selectedValues) && selectedValues.length > 0
                    ? selectedValues.map((value) => (
                        <JobChips key={value} customStyle="bg-gray-6" elem={value} />
                      ))
                    : !Array.isArray(selectedValues) && !!selectedValues
                    ? selectedValues
                    : placeholder}
                </span>
                <IoIosArrowDown
                  className={`duration-200 ease-in-out ${open ? 'rotate-180' : 'rotate-0'}`}
                />
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className={`slim-scroll fixed absolute z-50 max-h-60 w-full rounded-md bg-white py-1 text-base focus:outline-none sm:text-sm ${getPositionClasses()}`}
                  style={{
                    boxShadow: '1px 1px 6px 0 rgba(0, 0, 0, 0.2)',
                    display: open ? 'block' : 'none',
                  }}
                >
                  <Listbox.Options>
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.label || option}
                        value={option.label || option}
                        className={({ active }) =>
                          `${
                            active ? 'bg-[#D3D3D3]' : 'text-gray-900'
                          } relative cursor-default select-none py-2 pl-10 pr-4`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`${
                                selected ? 'font-satoshi-medium font-semibold' : 'font-normal'
                              } block truncate`}
                            >
                              {option.label || option}
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? 'text-white' : 'text-blue-600'
                                } absolute inset-y-0 left-0 flex items-center pl-3`}
                              >
                                ✔
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SelectDropDown;
