import React from 'react';
import JobChips from './JobChips';
import moment from 'moment';
import Link from 'next/link';
import { Primary, Secondary, SecondaryColor } from 'stories/Button.stories';

function JobsCard({ jobData = {} }) {
  const {
    jobId,
    title,
    companyName,
    location,
    postedAt,
    locationRequirement,
    minExperienceRequired,
    maxExperienceRequired,
    employmentType,
    domain,
    shortDescription,
    thumbnail,
  } = jobData;

  const PRE_FIX = 'https://grow.almabetter.com/job-board';
  const viewJDLink = `/jobs/${dynamicJDUrl(jobData)}`;

  const list = [
    locationRequirement,
    minExperienceRequired ? `${minExperienceRequired} years exp` : '',
    domain,
    employmentType,
  ];

  return (
    <div>
      <div
        className="flex flex-col gap-y-3 rounded-2xl bg-white p-4 md:rounded-lg md:px-4 md:py-5"
        style={{
          boxShadow:
            'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
        }}
      >
        <div className="flex items-start justify-between">
          <div className="flex items-start gap-x-4">
            {thumbnail && (
              <div className="h-16 w-16">
                <img
                  src={thumbnail}
                  className="h-14 w-14 rounded-full border border-[#E0E0E0] object-contain"
                />
              </div>
            )}

            <div className="flex flex-col">
              <h3 className="font-satoshi-bold text-lg text-[#3D3D3D]">{title?.trim()}</h3>
              <p className="font-satoshi-medium text-base text-[#646464]">
                {companyName}, {location}
              </p>
              <p className="font-satoshi-regular text-[14px] text-[#646464] md:hidden">
                {moment(postedAt).format('DD MMM, yyyy')}
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-wrap items-center gap-2">
            {list.map((li) => li && <JobChips key={li} elem={li} />)}
          </div>
          <p className="hidden font-satoshi-regular text-[14] text-[#646464] md:block">
            {moment(postedAt).format('DD MMM, yyyy')}
          </p>
        </div>
        <div className="inline-flex flex-col">
          <p className="font-satoshi-medium text-[16px] leading-5 text-[#646464] line-clamp-3">
            {shortDescription}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="grid grid-cols-2 items-center gap-x-4">
            <SecondaryColor linkCTA href={viewJDLink} label={'View JD'} />

            <Primary linkCTA href={`${PRE_FIX}/${jobId}`} target="_blank" label={'Apply'} />
          </div>
          <div className="flex items-center gap-x-4">
            <Secondary linkCTA href={`${PRE_FIX}/${jobId}`} label={'Save Job'} target="_blank" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobsCard;

function toHyphenString(str = '', isLocation = false) {
  if (isLocation) {
    return str.split(',')[0].toLowerCase();
  }

  if (str.includes('/')) {
    return removeExtraSymbol(str, '/');
  }

  if (str.includes(',')) {
    return removeExtraSymbol(str, ',');
  }

  return str.split(' ').join('-').toLowerCase();
}

function removeExtraSymbol(str = '', delimiter = '') {
  return str.split(delimiter)[0].split(' ').join('-').toLowerCase();
}

function dynamicJDUrl(jobData = {}) {
  const { title, companyName, location, minExperienceRequired, maxExperienceRequired, jobId } =
    jobData;

  let urlStr = '';

  if (title) {
    urlStr += `${toHyphenString(title)}-`;
  }

  if (companyName) {
    urlStr += `${toHyphenString(companyName)}-`;
  }

  if (location) {
    urlStr += `${toHyphenString(location, true)}-`;
  }

  if (minExperienceRequired && maxExperienceRequired) {
    urlStr += `${minExperienceRequired}-to-${maxExperienceRequired}-years-`;
  }

  urlStr += `job-${jobId}`;

  return urlStr;
}
