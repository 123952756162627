import React from 'react';
import ReferralCoinSvg from './ReferralCoinSvg';
import ReferralShareSvg from './ReferralShareSvg';

function ReferTag({ customStyle, link = '' }) {
  return (
    <a
      href={link}
      target="_blank"
      className={`flex cursor-pointer items-center gap-x-4 rounded-full bg-[#716FCD] py-1.5 pl-4 pr-1.5 shadow-lg md:shadow-none ${customStyle}`}
    >
      <p className="hidden text-[14px] text-white md:block">Share & earn cash on every sign up </p>
      <p className="flex items-center gap-x-4 text-base text-white md:hidden">
        <img src="/assets/gift-wrapper.svg" className="h-8 object-fill" />
        Share with your friends to earn cash in your wallet on every sign up via UPI.
      </p>

      <div className="hidden items-center gap-x-1 rounded-full bg-[#FBF2E1] px-2 md:flex">
        <ReferralCoinSvg />
        <p className="text-[#C86C00]">100</p>
        <span className="text-[#C86C00]">|</span>
        <ReferralShareSvg />
      </div>
    </a>
  );
}

export default ReferTag;
