import React, { useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci';

const SearchBox = ({ label, placeholder, query, changeHandler }) => {
  const [searchValue, setSearch] = useState('');
  const timer = useRef();

  useEffect(() => {
    clearTimeout(timer.current);

    if (!query && !searchValue) {
      return;
    }

    timer.current = setTimeout(() => {
      changeHandler('role', searchValue);
    }, 1000);
  }, [searchValue]);

  useEffect(() => {
    setSearch(query);
  }, [query]);

  return (
    <div className="relative w-full">
      {label && (
        <label className="block font-satoshi-medium text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="relative mt-1 flex items-center">
        <input
          type="text"
          value={searchValue}
          placeholder={placeholder}
          onChange={(e) => setSearch(e.target.value)}
          className="w-full rounded-xl border border-[#D3D3D3] py-2 pl-4 pr-10 font-satoshi-medium focus:outline-none"
        />
        <span className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-4">
          <CiSearch size={20} />
        </span>
      </div>
    </div>
  );
};

export default SearchBox;
