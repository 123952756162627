import React from 'react';

function JobChips({ customStyle = '', elem, name, changeHandler }) {
  return (
    <span
      className={`rounded-full border border-[#E0E0E0] px-3 py-1 font-satoshi-regular text-[14px] leading-4 ${customStyle}`}
      onClick={() => changeHandler && changeHandler(name, elem)}
    >
      {elem}
    </span>
  );
}

export default JobChips;
